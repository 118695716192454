<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select
					size="small"
					v-if="isAdmin"
					@change="agentChange"
					v-model="query.agentId"
					filterable
					clearable
					placeholder="代理商"
					class="handle-select mr10"
					style="width: 260px;"
				>
					<el-option v-for="item in agents" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-select
					size="small"
					v-if="isSubAgent"
					v-model="query.subAgentId"
					@change="getfetchDefaultCompanies"
					filterable
					clearable
					placeholder="子代理"
					class="handle-select mr10"
					style="width: 260px;"
				>
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px" v-model="query.companyId" filterable clearable placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="handleSearch">搜索</el-button>
				<el-button size="small" v-if="canAddCompany" type="primary" :class="[theme]" class="handle-add mr100" @click="showForm">新增来源标签</el-button>
			</div>
			<div>
				<el-table
					:data="tableData"
					border
					class="table"
					ref="multipleTable"
					header-cell-class-name="table-header"
					:row-style="{ height: '20px' }"
					:cell-style="{ padding: '6px' }"
					:header-cell-style="{ 'text-align': 'center' }"
					:row-class-name="tableRowClassName"
				>
					<el-table-column prop="laiyuan" label="来源" align="center"></el-table-column>

					<el-table-column label="操作" width="190" align="center">
						<template #default="scope">
							<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

		<el-dialog title="编辑标签" v-model="editVisible" width="500px" @close="closeDialog('form')" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="标签名称" prop="source"><el-input size="small" v-model="form.source"></el-input></el-form-item>
				<el-form-item class="dialog-footer" style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="updateCustomize('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="添加标签" v-model="addVisible" width="500px" @close="closeDialog('form')" :close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="标签名称" prop="source"><el-input size="small" v-model="form.source"></el-input></el-form-item>
				<el-form-item class="dialog-footer" style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="addCustomerSource('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import { list, delCompany, fetchCompanyById, fetchCompanyByAgentId, uptoken, uptoken1 } from '../api/companyIndex.js';
import { fetchAgent } from '../api/agentIndex';
import { fetchDefaultCompanies } from '../api/companyIndex';
import { getCustomerSource, updateCustomize, addCustomerSource, deleteCustomerSource } from '../api/tag.js';
import { querySubAgent } from '../api/serialIndex.js';
export default {
	name: 'company',
	data() {
		return {
			theme: localStorage.getItem('theme'),
			query: {
				companyId: '',
				userId: localStorage.getItem('user'),
				subAgentId: ''
			},
			tableData: [],
			companys: [],
			agents: [],
			addVisible: false,
			editVisible: false,
			canAddCompany: true,
			isAdmin: false,
			pageTotal: 0,
			loading1: false,
			isSubAgent: false,
			form: {
				source: []
			},
			loading: false,
			inputVisible: false,
			inputValue: ''
		};
	},

	created() {
		let role = localStorage.getItem('ms_role');
		this.subAgent = localStorage.getItem('subAgent');
		if (role === 'normal') {
			this.canAddCompany = false;
		}
		if (role === 'agent') {
			this.isNotAdmin = false;
			this.isAgent = true;
			if (this.subAgent == 'true') {
				this.isSubAgent = true;
				this.getquerySubAgent();
			} else {
				this.isSubAgent = false;
			}
		}
		if (role === 'subAgent') {
			this.isAgent = true;
		}
		if (role === 'admin') {
			this.isAdmin = true;
		}
		this.init();
		// this.getuptoken()
	},
	methods: {
		getfetchDefaultCompanies() {
			this.query.companyId = '';
			let data = {
				currentUserId: localStorage.getItem('user'),
				subAgentId: this.query.subAgentId
			};
			fetchDefaultCompanies(data).then(res => {
				this.companys = res.data;
				if (res.data.length > 0) {
					this.query.companyId = this.companys[0].value;
					this.query.pageIndex = 1;
					this.getData();
				}
			});
		},
		getquerySubAgent() {
			let data = {
				agentId: this.query.agentId,
				userId: localStorage.getItem('user')
			};
			querySubAgent(data).then(res => {
				if (res.code == 200) {
					this.subAgentList = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		handleClose(tag) {
			this.form.options.splice(this.form.options.indexOf(tag), 1);
		},

		showInput() {
			this.inputVisible = true;
			this.$nextTick(_ => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},

		handleInputConfirm() {
			let inputValue = this.inputValue;
			console.log(inputValue, 'handleInputConfirminputtimeArr1111');
			if (inputValue) {
				this.form.options.push(inputValue);
			}
			console.log(this.form.options, 'this.form.options');
			this.inputVisible = false;
			this.inputValue = '';
		},

		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		init() {
			fetchAgent(localStorage.getItem('user')).then(res => {
				this.agents = res.data;
			});

			if (this.subAgent == true) {
				this.isSubAgent = true;
				this.getquerySubAgent();
			}
			this.getfetchDefaultCompanies();
		},
		agentChange(agentId) {
			this.query.companyId = '';
			this.query.userId = '';
			this.form.companyId = '';
			this.form.userId = '';
			if (agentId) {
				let data = {
					subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
					agentId: agentId
				};
				fetchCompanyByAgentId(data).then(res => {
					this.companys = res.data;
				});
			}
		},

		getData() {
			if (this.query.companyId) {
				getCustomerSource(this.query.companyId).then(res => {
					if (res.code == 200) {
						let data = [];
						res.data.forEach((i, index) => {
							let obj = {
								laiyuan: i
							};
							data.push(obj);
						});
						this.tableData = data;
					} else {
						this.$message.error(res.message);
					}
				});
			} else {
				getCustomerSource(this.agents[0].value).then(res => {
					if (res.code == 200) {
						let data = [];
						res.data.forEach((i, index) => {
							let obj = {
								laiyuan: i
							};
							data.push(obj);
						});
						this.tableData = data;
					} else {
						this.$message.error(res.message);
					}
				});
			}
		},
		showForm() {
			if (this.query.companyId) {
				this.addVisible = true;
			} else {
				this.$message.error('请选择企业');
			}
		},

		// 点击确定上传信息
		addCustomerSource(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					let data = {
						companyId: this.query.companyId,
						source: this.form.source
					};
					addCustomerSource(JSON.stringify(data)).then(res => {
						if (res.code == 200) {
							this.$message.success(res.data);
							this.addVisible = false;
							this.getData();
						} else {
							this.$message.error(res.message);
							this.getData();
						}
					});
				}
			});
		},

		// 编辑操作
		handleEdit(index, row) {
			this.editVisible = true;
			this.form = row;
		},
		// 点击修改信息
		updateCustomize(formName) {
			// this.loading2 = true;
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.form.companyId = this.query.companyId;
					let data = JSON.stringify(this.form);
					updateCustomize(data).then(res => {
						if (res.code == 200) {
							this.$message.success('修改成功');
							this.getData();
						} else {
							this.$message.error(res.message);
						}
						this.editVisible = false;
					});
				}
			});
		},
		// 触发搜索按钮
		handleSearch() {
			this.query.pageIndex = 1;
			this.getData();
		},
		// 删除操作
		handleDelete(index, row) {
			// 二次确认删除
			this.$confirm('确定要删除吗？', '提示', {
				type: 'warning'
			})
				.then(() => {
					let data = {
						index: index,
						companyId: this.query.companyId
					};
					deleteCustomerSource(data).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.pageTotal = this.pageTotal - 1;
							this.getData();
						} else {
							this.$message.error(res.message);
						}
					});
				})
				.catch(() => {});
		},
		// 分页导航
		handlePageChange(val) {
			this.query.pageIndex = val;
			this.getData();
		},
		resetForm(formName) {
			this.editVisible = false;
			this.addVisible = false;
			(this.form = {
				label: '',
				options: '',
				companyId: '',
				addoptions: ''
			}),
				this.resetForm(formName);
		},
		// 点击取消
		closeDialog(formName) {
			this.editVisible = false;
			this.addVisible = false;
			this.form = {
				label: ''
			};
			this.getData();
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover {
	background: #66b1ff;
} */
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 12px;
}
.red {
	color: #f56c6c;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
.el-form-item__label {
	font-size: 12px !important;
}

.hide .el-upload--picture-card {
	display: none;
}
.content_top {
	display: flex;
	justify-content: space-around;
}
.content_bottom {
	display: flex;
	justify-content: space-around;
}
.content_middle {
	display: flex;
	margin: 0.625rem 0 0.625rem 0;
	/* margin-bottom: 0.625rem; */
	justify-content: space-around;
}
.ywlx:hover {
	color: #00a854;
	background: none;
}
.content_top {
	display: flex;
	margin: auto;
}
</style>
